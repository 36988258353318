import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-paypalcancel',
  templateUrl: './paypalcancel.component.html',
  styleUrls: ['./paypalcancel.component.css']
})
export class PaypalcancelComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
