import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, Subscription,Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CharityadminService {


 isAdmin : boolean;
 isShowAdminMenu : boolean ;

 isApplicationLoaded : boolean = false; //if an existing application has been loaded

 public applicationStatus : string = ""; // No direct access. Only through subscription //TEST made access public
 code: string = "";  //for display on Admin Banner

 applicationStatusChange : Subject<string> = new Subject<string>();

  constructor(private http: HttpClient) {
    this.isShowAdminMenu = false;          // ACHTUNG !! set to FALSE before committing.
    this.isAdmin = false;
  }


  // Broadcast application status change to all subscribers
  public updateApplicationStatus(status : string) {
    this.applicationStatus = status;
    this.applicationStatusChange.next(status);
  }


  //List of all NonProfit applications (summary)
  public getApplicationsSummary() {

    const appSummaryListEndpoint =  environment.server_url + 'secconlineapp/admin/getapplications';
    const headers = new Headers({ 'Content-type': 'application/json' });
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.get(appSummaryListEndpoint, httpOptions);
  }



  //Search for summaries
  public searchApplicationsSummary(refno : string, title : string) {

      const appSummaryListEndpoint =  environment.server_url + 'secconlineapp/admin/searchapplications';
      const  params = new HttpParams()
                      .append('refno',refno)
                      .append('title', title);

      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
      };
      return this.http.post(appSummaryListEndpoint, params, httpOptions);
  }

  //Retrieve an application
  public retrieveApplication(refno : string) {

    const retrieveAppEndpoint =  environment.server_url + 'secconlineapp/admin/retrieveapplication';
    const  params = new HttpParams()
                    .append('refno',refno);

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
    };
    return this.http.post(retrieveAppEndpoint, params, httpOptions);
  }

  public updateNonProfitStatus(status : string) {

    const updateNonProfitStatusEndpoint =  environment.server_url + 'secconlineapp/admin/updatenonprofitstatus';

    const  params = new HttpParams()
                    .append('status',status);

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
      responseType: 'text' as 'text'
    };
    return this.http.post(updateNonProfitStatusEndpoint, params, httpOptions);

  }


  //TEST TODO check & undo
  showApplicationStatus() {

    const statusMap = new Map([
      ["A","APPROVED"],
      ["P","PENDING"],
      ["PL","PROVISIONAL"],
      ["D","DECLINED"],
      ["R","REVIEWED"],
      ["INC","INCLUDED"]
    ]);

    const colorMap = new Map([
      ["A","rgb(6, 83, 32)"],
      ["P","rgb(255, 255, 255)"],
      ["PL","rgb(243, 247, 8)"],
      ["D","rgb(202, 29, 46)"],
      ["R","rgb(30, 43, 158)"],
      ["INC","rgb(127, 1, 127)"]
    ]);

    var status = this.applicationStatus;
    console.log(document.getElementById("applicationStatusBand"));
    document.getElementById("applicationStatusBand").style.backgroundColor= colorMap.get(status);   //applicationStatusText
    if(status=='PL' || status=='P') {
      document.getElementById("applicationStatusText").style.color= "black";
    } else {
      document.getElementById("applicationStatusText").style.color= "white";
    }

    return statusMap.get(this.applicationStatus);
  }


    //Authorization
    getAuthorizedAdmins() {
      const authorizedAdminsEndpoint = environment.server_url + 'secconlineapp/admin/getauthorizedadmins';
      const headers = new Headers({ 'Content-type': 'application/json' });
      const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
      return this.http.get(authorizedAdminsEndpoint, httpOptions);
    }

    saveAdmin(ein : string) {
      const saveAdminEndpoint = environment.server_url + 'secconlineapp/admin/saveadmin?ein=' + ein;
      const headers = new Headers({ 'Content-type': 'application/json' });
      const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
      return this.http.get(saveAdminEndpoint, httpOptions);
    }

    deleteAdmin(ein : string) {
      const deleteAdminEndpoint = environment.server_url + 'secconlineapp/admin/deleteadmin?ein=' + ein;
      const headers = new Headers({ 'Content-type': 'application/json' });
      const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
      return this.http.get(deleteAdminEndpoint, httpOptions);
    }






}
