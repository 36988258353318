import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { CharityadminService } from '../../../services/charityadmin.service';

import { SessiontimeoutService } from 'src/app/services/sessiontimeout.service';

@Component({
  selector: 'app-authorize',
  templateUrl: './authorize.component.html',
  styleUrls: ['./authorize.component.css']
})
export class AuthorizeComponent implements OnInit {

  constructor(
    private charityadminSvc : CharityadminService,
    private sessiontimeoutService: SessiontimeoutService,
    private router: Router) {

  }

  getAdminsSubscription = new Subscription();
  saveAdminSubscription = new Subscription();
  deleteAdminSubscription = new Subscription();
  authorizedAdmins: Array<string>;

  //Add New
  newEin : string;

  ngOnInit(): void {

    this.sessiontimeoutService.reset();
    this.getAuthorizedAdmins();
  }

  getAuthorizedAdmins() {

    console.log("starting getauthorizedadmins: ");
    this.getAdminsSubscription = this.charityadminSvc.getAuthorizedAdmins().subscribe(
      (res) => {
        this.authorizedAdmins = res as Array<string>;
      },
      (err) => {
        console.log(err);
      });
  }


  saveAdmin( ) {

    this.saveAdminSubscription = this.charityadminSvc.saveAdmin(this.newEin).subscribe(
      (res) => {
          this.authorizedAdmins = res as Array<string>;
          this.newEin = '';
      },
      (err) => {
        console.log(err);
      });
  }

  deleteAdmin(ein : string) {

    this.deleteAdminSubscription = this.charityadminSvc.deleteAdmin(ein).subscribe(
      (res) => {
          this.authorizedAdmins = res as Array<string>;
      },
      (err) => {
        console.log(err);
      });
  }


  ngOnDestroy() {

    if (this.getAdminsSubscription != undefined) this.getAdminsSubscription.unsubscribe();
    if (this.saveAdminSubscription != undefined) this.saveAdminSubscription.unsubscribe();
    if (this.deleteAdminSubscription != undefined) this.deleteAdminSubscription.unsubscribe();
  }

}
