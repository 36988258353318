

      <div id="container">


        SECC CHARITY & SECC DONATE Admins : <p></p>
        <p></p>
        <table>

          <tr *ngFor="let item of authorizedAdmins">
            <td>{{item.ein}}</td>
            <td>|</td>
            <td>{{item.firstName}}</td>
            <td>{{item.lastName}}</td>
            <td><img src="assets/img/delete.png" style="cursor: pointer;" (click)="deleteAdmin(item.ein)"></td>
          </tr>
          <tr><td>&nbsp;</td></tr>
          <tr><td>&nbsp;</td></tr>
          <tr><td>&nbsp;</td></tr>

        </table>

        <div>
          <table>
            <tr>
              <td>
            <form id="AddNewForm" method="post">
              <input type="text" size="9" id="newEinBox" name="newEinBox" (keyup.enter)="saveAdmin()" [(ngModel)]="newEin" placeholder="< EIN >"/>
            </form>
          </td>
          <td>
            <input type="button" (click)="saveAdmin()" value="Add" />
          </td>
          </tr>
          </table>
        </div>



      </div>

