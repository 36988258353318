<table width="100%">
  <tr>
    <td align="center">
      <br />
      <span style="color:Red; font-size:large;">PayPal Transaction was cancelled.</span>

      <br /><br />
    </td>

  </tr>
  <tr>
    <td></td>
  </tr>
  <tr>
    <td align="center"><a [routerLink]="'/instructions'">SECC Home</a></td>
  </tr>
  <tr>
    <td></td>
  </tr>
</table>
